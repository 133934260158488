<template>
  <b-overlay :show="formSubmitted" :spinner-variant="colorVariant('', 'primary')" variant="dark" opacity="0.85"
    :spinner-type="spinnerType" spinner rounded="sm">
    <transition name="slide-fade" mode="out-in">
      <configurations v-if="editMode" @change="changeSelectedAccount" />

      <b-card v-else no-body :border-variant="options.border">
        <b-card-header v-if="options.showTitle || options.showAccount">
          <div>
            <b-card-title v-if="options.showTitle" class="mb-1">
              <span v-if="selectedAccount">{{ totalItems }}</span> {{ $t('components.cards.meli_items.title') }}
            </b-card-title>
            <b-card-sub-title v-if="options.showAccount && !account" class="text-white">
              <span v-if="selectedAccount">{{ selectedAccount.nickname }}</span>
              <span v-else>{{ $t('components.cards.common.select_account') }}</span>
            </b-card-sub-title>
          </div>
          <div class="d-flex align-items-center">
            <b-link @click="getData" v-if="options.showRefresh">
              <feather-icon icon="RotateCwIcon" size="16" />
            </b-link>
          </div>
        </b-card-header>

        <b-card-header>
          <div>
            <b-card-sub-title>
              {{ filterType }}
            </b-card-sub-title>
          </div>
        </b-card-header>

        <b-card-body>
          <span v-for="item in items" :key="item.index">
              <b-badge pill :variant="item.color" class="mr-1">{{ item.results }}</b-badge>
          </span>
        </b-card-body>

        <b-card-body v-if="false">
          <b-row>
            <b-col v-for="item in items" :key="item.index" md="3" sm="6" class="mb-2 mb-md-0">
              <b-media no-body>
                <b-media-aside class="mr-2">
                  <b-avatar size="48" :variant="item.color">
                    <feather-icon size="24" icon="TagIcon" />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{ item.results }}
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    {{ item.name }}
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </transition>
  </b-overlay>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BCardTitle, BCardSubTitle, BCardText, BMedia, BMediaAside, BMediaBody, BAvatar, BOverlay, BRow, BCol, BBadge, BLink,
} from 'bootstrap-vue'
import handleAxiosException from '@core-md/mixins/errors'
import variants from '@core-md/mixins/variants'
import { $themeConfig } from '@themeConfig'
import configurations from './components/CardMeliItemsConfigurations.vue'
import meliItems from '@/services/meli/items'

export default {
  components: {
    BCardHeader,
    BCard,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BOverlay,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BAvatar,
    BRow,
    BCol,
    BBadge,
    BLink,
    configurations,
  },
  props: {
    account: {
      type: Object,
      default: null,
    },
    filterType: {
      type: String,
      default: 'listing_type_id',
    },
    showZeros: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: null,
    },
  },
  provide() {
    return {
      options: this.options,
      selectedAccount: this.selectedAccount,
    }
  },
  mixins: [
    variants,
    handleAxiosException,
  ],
  data() {
    return {
      totalItems: 0,
      items: [],
      selectedAccount: null,
      formSubmitted: false,
    }
  },
  computed: {
    editMode() {
      return this.$store.state.dashboard.editMode
    },
    accounts() {
      return this.$store.state.authorizations.accounts
    },
  },
  setup() {
    const { spinnerType } = $themeConfig.layout

    return {
      spinnerType,
    }
  },
  mounted() {
    if (this.account) {
      this.selectedAccount = this.account
    }
    this.loadOptions()
    this.getData()
  },
  methods: {
    loadOptions() {
      if (this.options) {
        this.showTitle = this.options.showTitle
        this.showAccount = this.options.showAccount
        this.showRefresh = this.options.showRefresh
        this.showZeros = this.options.showZeros
        this.filterType = this.options.filterType
        if (this.options.sellerId) {
          this.selectedAccount = this.getSelectedAccount(this.options.sellerId)
        }
      }
    },
    getSelectedAccount(sellerId) {
      const account = this.$store.state.authorizations.accounts.filter(filter => filter.seller_id === sellerId)
      if (account && account.length > 0) {
        return account[0]
      }
      return null
    },
    getData() {
      if (this.selectedAccount) {
        this.formSubmitted = true
        this.items = []
        meliItems.getActiveItemsSimple(this.selectedAccount.access_token, this.selectedAccount.seller_id).then(result => {
          if (result.httpCode === 200) {
            if (result.body.available_filters) {
              console.log(result.body.available_filters)
              let availableFilters = result.body.available_filters.filter(filter => filter.id === this.filterType)
              const sum = availableFilters.reduce((r, a) => {
                let auxSum = 0
                a.values.forEach(b => {
                  auxSum += b.results
                })
                r.push(auxSum)
                return r
              }, [])
              if (!this.showZeros) {
                availableFilters = availableFilters.pop().values.filter(filter => filter.results > 0)
              }
              this.totalItems = sum.pop()
              availableFilters.forEach(element => {
                /* eslint-disable no-param-reassign */
                if (element.id === 'gold_pro') {
                  element.color = 'primary'
                } else {
                  element.color = 'light-primary'
                }
              })
              this.items = availableFilters
              console.log(this.items)
            }
            this.formSubmitted = false
          }
        }).catch(data => {
          // context.commit('UPDATE_STATUS_MESSAGE', data)
          console.log(data)
          this.formSubmitted = false
        })
      }
    },
    changeSelectedAccount(selected) {
      if (selected) {
        this.options.sellerId = selected.seller_id
      }
      this.selectedAccount = selected
      this.getData()
    },
  },
}
</script>

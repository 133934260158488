import meliClient from './core/client'

export default {
  getNotices(accessToken) {
    const body = {
      method: 'GET',
      url: 'communications/notices',
      parameters: {
        access_token: accessToken,
      },
    }
    return meliClient.callMeliProcess(body)
  },
}

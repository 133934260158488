<template>
  <div>
    <b-row v-if="options.mode === 'horizontal'" class="match-height">
      <b-col :lg="component.size" v-for="component in children" :key="component.index">
        <component :is="component.name" :options="component.options" :children="component.children" />
      </b-col>
    </b-row>
    <b-row v-else v-for="component in children" :key="component.index">
      <b-col :lg="component.size">
        <component :is="component.name" :options="component.options" :children="component.children" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'
import meliVisits from '@/layouts/components/cards/CardMeliVisits.vue'
import meliNotices from '@/layouts/components/cards/CardMeliNotices.vue'
import meliItems from '@/layouts/components/cards/CardMeliItems.vue'

export default {
  name: 'contentComponent',
  components: {
    BRow,
    BCol,
    meliVisits,
    meliNotices,
    meliItems,
  },
  data() {
    return {
      components: [],
    }
  },
  props: {
    children: {
      type: Array,
    },
    options: {
      type: Object,
      default: null,
    },
  },
}
</script>

<template>
  <b-card no-body>
    <b-card-header>
      <div>
        <b-card-title class="mb-1">
          {{ $t('components.cards.meli_items.configurations') }}
        </b-card-title>
      </div>
      <div class="d-flex align-items-center">
        <simple-account-selector @change="changeSelectedAccount"></simple-account-selector>
      </div>
    </b-card-header>
    <b-card-body>
      <b-form-checkbox v-model="options.showTitle" checked="true" switch inline>
        {{ $t('components.cards.common.show_title') }}
      </b-form-checkbox>
      <b-form-checkbox v-model="options.showAccount" checked="true" switch inline>
        {{ $t('components.cards.common.show_account') }}
      </b-form-checkbox>
      <b-form-checkbox v-model="options.showRefresh" checked="true" switch inline>
        {{ $t('components.cards.common.allow_refresh') }}
      </b-form-checkbox>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BCardTitle, BFormCheckbox,
} from 'bootstrap-vue'
import simpleAccountSelector from './SimpleAccountSelector.vue'

export default {
  components: {
    BCardHeader,
    BCard,
    BCardBody,
    BCardTitle,
    BFormCheckbox,
    simpleAccountSelector,
  },
  inject: [
    'options',
  ],
  methods: {
    changeSelectedAccount(selected) {
      this.$emit('change', selected)
    },
  },
}
</script>

<template>
  <b-overlay :show="formSubmitted" :spinner-variant="colorVariant('', 'primary')" variant="dark" opacity="0.85"
    :spinner-type="spinnerType" spinner rounded="sm">
    <transition name="slide-fade" mode="out-in">
      <configurations v-if="editMode" @change="changeSelectedAccount" />

      <b-card no-body v-else>
        <b-card-header v-if="options.showTitle || options.showAccount">
          <div>
            <b-card-title v-if="options.showTitle" class="mb-1">
              {{ $t('components.cards.meli_notices.title') }}
            </b-card-title>
            <b-card-sub-title v-if="options.showAccount && !account">
              <span v-if="selectedAccount">{{ selectedAccount.nickname }}</span>
              <span v-else>{{ $t('components.cards.common.select_account') }}</span>
            </b-card-sub-title>
          </div>
          <div class="d-flex align-items-center">
            <b-link @click="getData" v-if="options.showRefresh">
              <feather-icon icon="RotateCwIcon" size="16" />
            </b-link>
          </div>
        </b-card-header>

        <b-card-body class="p-0">
          <b-alert show variant="primary" v-for="message in messages" :key="message.id" class="m-0">
            <div class="alert-body d-flex">
              <span class="flex-grow-1 align-self-center">
                <feather-icon class="mr-25" icon="InfoIcon" size="18" />
                <strong>{{ message.label }}</strong> {{ message.description }}
              </span>
              <span v-if="message.actions" class="align-self-center">
                <b-button :href="action.link" target="_blank" size="sm" variant="primary" v-for="action in message.actions"
                  :key="action.index">
                  {{ action.text }}
                </b-button>
              </span>
            </div>
          </b-alert>
        </b-card-body>
      </b-card>
    </transition>
  </b-overlay>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BCardTitle, BCardSubTitle, BOverlay, BAlert, BButton, BLink,
} from 'bootstrap-vue'
import handleAxiosException from '@core-md/mixins/errors'
import variants from '@core-md/mixins/variants'
import { $themeConfig } from '@themeConfig'
import configurations from './components/CardMeliNoticesConfigurations.vue'
import meliGeneral from '@/services/meli/general'

export default {
  components: {
    BCardHeader,
    BCard,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BOverlay,
    BAlert,
    BButton,
    BLink,
    configurations,
  },
  props: {
    account: {
      type: Object,
      default: null,
    },
    config: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: null,
    },
  },
  provide() {
    return {
      options: this.options,
      selectedAccount: this.selectedAccount,
    }
  },
  mixins: [
    variants,
    handleAxiosException,
  ],
  data() {
    return {
      messages: [],
      selectedAccount: null,
      formSubmitted: false,
    }
  },
  computed: {
    editMode() {
      return this.$store.state.dashboard.editMode
    },
    accounts() {
      return this.$store.state.authorizations.accounts
    },
  },
  setup() {
    const { spinnerType } = $themeConfig.layout

    return {
      spinnerType,
    }
  },
  mounted() {
    if (this.account) {
      this.selectedAccount = this.account
    }
    this.loadOptions()
    this.getData()
  },
  methods: {
    loadOptions() {
      if (this.options) {
        this.showTitle = this.options.showTitle
        this.showAccount = this.options.showAccount
        this.showRefresh = this.options.showRefresh
        if (this.options.sellerId) {
          this.selectedAccount = this.getSelectedAccount(this.options.sellerId)
        }
      }
    },
    getSelectedAccount(sellerId) {
      const account = this.$store.state.authorizations.accounts.filter(filter => filter.seller_id === sellerId)
      if (account && account.length > 0) {
        return account[0]
      }
      return null
    },
    getData() {
      if (this.selectedAccount) {
        this.formSubmitted = true
        this.messages = []
        meliGeneral.getNotices(this.selectedAccount.access_token).then(result => {
          if (result.httpCode === 200) {
            if (result.body.results) {
              this.messages = result.body.results
            }
            this.formSubmitted = false
          }
        }).catch(data => {
          // context.commit('UPDATE_STATUS_MESSAGE', data)
          console.log(data)
          this.formSubmitted = false
        })
      }
    },
    changeSelectedAccount(selected) {
      if (selected) {
        this.options.sellerId = selected.seller_id
      }
      this.selectedAccount = selected
      this.getData()
    },
  },
}
</script>

<template>
  <div>
    <b-button variant="primary" @click="saveDashboard">Salvar Dashboard</b-button>&nbsp;
    <b-button variant="primary" v-if="!editMode" @click="enableEditMode">Habilitar Edição</b-button>&nbsp;
    <b-button variant="primary" v-if="editMode" @click="disableEditMode">Desabilitar Edição</b-button><br /><br />
    <content-component :children="content.children" :options="content.options"></content-component>
  </div>
</template>

<script>
import {
  BButton,
} from 'bootstrap-vue'
import contentComponent from '@/layouts/components/ContentComponent.vue'

export default {
  components: {
    BButton,
    contentComponent,
  },
  data() {
    return {
    }
  },
  computed: {
    content() {
      if (this.$store.state.dashboard.dashboards && this.$store.state.dashboard.dashboards.length > 0) {
        return this.$store.state.dashboard.dashboards[0]
      }
      return {
        options: {
          mode: 'vertical',
        },
      }
    },
    editMode() {
      return this.$store.state.dashboard.editMode
    },
  },
  created() {
    this.$store.dispatch('authorizations/refreshAuthorizations')
  },
  mounted() {
    this.$store.dispatch('dashboard/loadDashboard')
  },
  methods: {
    saveDashboard() {
      localStorage.setItem('dashboard', JSON.stringify(this.content))
    },
    enableEditMode() {
      this.$store.dispatch('dashboard/enableEditMode')
    },
    disableEditMode() {
      this.$store.dispatch('dashboard/disableEditMode')
    },
  },
}
</script>
